import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Phone from "../../device/phone";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import appDashboard from "../../../images/screenshot/dyslexia-ai/dashboard.webp";
import appProfile from "../../../images/screenshot/dyslexia-ai/profile.webp";
import appGame from "../../../images/screenshot/dyslexia-ai/game.webp";
import appVideoMp4 from "../../../assets/dyslexia-ai/profile_compressed.mp4";
import appVideoWebm from "../../../assets/dyslexia-ai/profile_compressed.webm";

export default function DyslexiaAiHero(props) {
  return (
    <div className="relative text-primaryDyslexia bg-invisible pt-10">
      <svg class="absolute h-full w-full inset-0" xmlns="http://www.w3.org/2000/svg">
        <circle cx="0%" cy="50%" r="300" stroke="#fefefe" stroke-width="10" fill="none"/>
        <circle cx="80%" cy="50%" r="500" stroke="#fefefe80" stroke-width="10" fill="none"/>
        <circle cx="100%" cy="50%" r="400" stroke="#fefefe" stroke-width="10" fill="none"/>
    </svg>
      <section className="w-screen">
        <section className="container lg:flex lg:p-20">
          
          <div className="flex-auto m-auto">
            <StaticImage
              src="../../../images/icon/dyslexia-ai.png"
              alt="Dyslexia.ai - Games and Tools for Dyslexic Students"
              className="h-20 lg:h-40 rounded-3xl mx-auto my-2"
              placeholder="none"
              layout="fixed"
              width={100}
              height={100}
            />

            <h3 className="font-comfortaa font-bold text-primaryDyslexia text-center text-3xl lg:text-4xl p-2">
              Dyslexia<span className="opacity-20">.</span>ai
            </h3>
            <h4 className="font-main font-semibold text-primaryDyslexia/50 text-center">
              Games and Tools for Dyslexic Students
            </h4>

            <div class="container mx-auto text-center p-10 transform hover:opacity-70 z-1">
              <OutboundLink
                class="z-1"
                href="https://apps.apple.com/ie/app/dyslexia-ai/id1564885095?itsct=apps_box_badge&amp;itscg=30200">
                <img
                  className="mx-auto"
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1626825600&amp;h=6958afb9bdf247e7f172d176cbffef3b"
                  alt="Download on the App Store"
                />
              </OutboundLink>
            </div>
          </div>

          <div className="flex lg:flex-row p-4 mx-auto">
            <div className="mt-10 transform rotate-12 -skew-y-6 z-10 ">
              <Phone
                id={"dyslexia-ai-video"}
                poster={appDashboard}
                mp4={appVideoMp4}
                webm={appVideoWebm}
              />
            </div>
          </div>

        </section>

        <section className="grid grid-cols-2 gap-2 mx-auto py-10 max-w-lg">
          <div className="flow p-4">
            <p className="font-main font-semibold text-4xl p-2 text-center">
              10<span className="opacity-20">,</span>000
              <span className="opacity-50">+</span>
            </p>
            <p className="font-title text-sd opacity-50 text-center">
              Dictionary of Words
            </p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-6xl p-2 text-center">
              44
            </p>
            <p className="font-title text-sd text-center opacity-50">Phonics</p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-4xl p-2 text-center">
              1000<span className="opacity-20">+</span>
            </p>
            <p className="font-title text-sd text-primaryDyslexia/50 text-center">
              3D Rewards
            </p>
          </div>

          <div className="flow-col p-4">
            <p className="font-main font-semibold text-4xl p-2 text-center">
              4
            </p>
            <p className="font-title text-sd  text-center">
              <span className="opaci  ty-50">Languages</span> <br /> 🇺🇸 🇬🇧 🇪🇸 🇩🇪
              🇫🇷
            </p>
          </div>
        </section>
      </section>
    </div>
  );
}
